<template>
<div class="liff-lession-page-videos p-5">
  <div
    class="video-card letwecare-liff-lession-card"
    v-for="(item, idx) in data"
    v-if="data.length > 0"
  >
    <div class="card__header">
      <div class="header-title">{{ item.name }}</div>
      <div class="header-description">{{ item.description }}</div>
    </div>

    <div class="card__body">
      <iframe
        width="100%" height="240"
        :src="`https://www.youtube.com/embed/${youtubeIdParser(item.url)}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>

  <div v-if="data.length == 0">
    <a-empty class="mt-10" :image="simpleImage" description="沒有影片" />
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { Empty } from 'ant-design-vue'

export default defineComponent({
  props: ['data'],
  components: {},
  setup() {
    function youtubeIdParser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    }

    return {
      youtubeIdParser,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  methods: {

  }
})
</script>

<style lang="scss">
.liff-lession-page-videos {
  .video-card {
    margin-bottom: 16px;

    .card__header {
      padding: 20px 24px;

      .header-title {
        font-size: 1.4rem;
        font-weight: 450;
      }

      .header-description {
        color: #424242;
        font-size: 1.2rem;
      }
    }

    // .card__actions {
    //   .action-btn {
    //     width: 100%;
    //     font-size: 1.2rem;
    //     height: 44px;
    //   }
    // }
  }
}
</style>