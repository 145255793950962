<template>
  <div class="liff-lession-page">
    <div class="page__toolbar">
      <div class="toolbar-title">{{ courseData.name }}</div>
    </div>

    <div class="page__header">
      <div class="header-actions">
        <a-button
          class="action-btn"
          type="link"
          :style="{ color: '#FF8A00' }"
          @click="backToCoursePage"
        >返回</a-button>
      </div>

      <div class="header-text">{{ lessionData.name }}</div>

      <!-- <a-row>
        <a-col :span="6">
          <div class="header-actions">
            <a-button
              class="action-btn"
              type="link"
              :style="{ color: '#FF8A00' }"
              @click="backToCoursePage"
            >返回</a-button>
          </div>
        </a-col>
        <a-col :span="18">
          <div class="header-text">{{ lessionData.name }}</div>
        </a-col>
      </a-row> -->

      <a-row>
        <a-col :span="24">
          <a-tabs class="header-tabs" v-model:activeKey="activeTabKey" centered>
            <a-tab-pane key="1" tab="運動成就" />
            <a-tab-pane key="2" tab="回家作業" />
            <a-tab-pane key="3" tab="影片資源" />
            <a-tab-pane key="4" tab="課堂照片" />
          </a-tabs>
        </a-col>
      </a-row>
    </div>

    <div class="page__body" v-if="!loading">
      <template v-if="activeTabKey == '1'">
        <Achievements
          :userId="userId"
          :images="lessionData.images"
          @changeTab="handleTabChanged"
        />
      </template>

      <template v-if="activeTabKey == '2'">
        <Homeworks
          :userId="userId"
          :data="lessionData.homeworks"
        />
      </template>

      <template v-if="activeTabKey == '3'">
        <Videos
          :data="lessionData.videos"
        />
      </template>

      <template v-if="activeTabKey == '4'">
        <Images
          :data="lessionData.images"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import liff from '@line/liff/dist/lib'
import api from '@/apis'
import Achievements from './components/Achievements.vue'
import Homeworks from './components/Homeworks.vue'
import Videos from './components/Videos.vue'
import Images from './components/Images.vue'

export default defineComponent({
  components: {
    Achievements,
    Homeworks,
    Videos,
    Images
  },
  setup() {
    const route = useRoute();

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_MY_COURSES });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Loading */
    const loading = ref(true);

    /* Data */
    const userId = ref('');
    const courseData = ref({});
    const lessionData = ref({});

    /* Tab */
    // const changeTab = function () {
    //   getLessionData()
    // }

    /* Api */
    const getUserId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      userId.value = response.data.result.uid;
    });

    const getCourseData = (async () => {
      courseData.value = {};
      
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    const getLessionData = (async () => {
      lessionData.value = {};

      const response = await api.v1.lession.findOne(route.params.lession_id);

      lessionData.value = response.data.result;
      console.log(lessionData.value)
    });

    /* Mounted */
    onMounted(async () => {
      await initLiff();
      await getUserId();
      await getCourseData();
      await getLessionData();

      loading.value = false;
    });

    return {
      /* Tab */
      activeTabKey: ref('1'),

      /* Loading */
      loading,

      /* Data */
      userId,
      courseData,
      lessionData

      /* Tab */
      // changeTab
    }
  },
  methods: {
    /* Router */
    backToCoursePage() {
      this.$router.push({
        name: 'LiffCourseList',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },

    /* Tab */
    handleTabChanged(key) {
      this.activeTabKey = key;
      window.scrollTo(0, 0);
    }
  }
})
</script>

<style lang="scss">
.liff-lession-page {
  min-height: 100vh;
  background: #EAEAEA;

  .page__toolbar {
    height: 57px;
    background: #3D3D3D;

    .toolbar-title {
      color: white;
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
      letter-spacing: 0.0425rem;
      line-height: 57px;
    }
  }

  .page__header {
    padding: 0 20px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    background: white;
    position: sticky;
    top: 0;
    z-index: 9;

    .header-actions {
      padding: 6px 0;
      position: absolute;
      left: 16px;

      .action-btn {
        font-size: 1rem;
        padding: 0;
      }
    }

    .header-text {
      font-size: 1.15rem;
      font-weight: 500;
      text-align: center;
      padding: 8px 0;
    }

    .header-tabs {
      &.ant-tabs {
        .ant-tabs-nav {
          margin-bottom: 0;

          .ant-tabs-tab .ant-tabs-tab-btn {
            color: #424242;
            font-size: 1.1rem;
          }

          .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #FF8900;
          }

          .ant-tabs-ink-bar {
            background: #FF8900;
          }
        }
      }
    }
  }

  .page__body {
    // padding: 20px;
  }
}
</style>