<template>
<div class="liff-lession-page-achievements p-5">
  <div class="achievement-card letwecare-liff-lession-card">
    <div class="card__header">
      <div class="header-title">運動成果</div>
    </div>
    
    <div
      class="card__body"
      v-if="trainingRecordsData && images"
    >
      <div class="body-main" v-if="trainingRecordsData.length">
        <!-- Training Records -->
        <div class="training-record-list">
          <a-row
            class="record-item"
            v-for="(item, idx) in trainingRecordsData"
          >
            <a-col :span="6">
              <div class="item-image">
                <TrainingIcon :actionName="item.action_name" />
              </div>
            </a-col>

            <a-col :span="18">
              <div class="item-title">{{ item.action_name }}</div>
              <a-tag class="item-tag">{{ item.set }} <span style="font-size:0.5rem">組</span></a-tag>
              <template v-if="item.rep">
                <a-tag class="item-tag">{{ item.rep }} <span style="font-size:0.5rem">下</span></a-tag>
              </template>
              <template v-else-if="item.second">
                <a-tag class="item-tag">{{ item.second }} <span style="font-size:0.5rem">秒</span></a-tag>
              </template>
              <template v-else-if="item.failure">
                <a-tag class="item-tag">力竭</a-tag>
              </template>
              

              <template
                v-if="
                  !item.userTrainingRecord.tagInputVisible &&
                  item.userTrainingRecord.tagInputValue
                "
              >
                <a-tag
                  class="item-tag"
                  closable
                  @close="handleUserTrainingRecordDelete(item)"
                >{{item.userTrainingRecord.tagInputValue }} <span style="font-size:0.5rem">公斤</span></a-tag>
              </template>

              <template v-else>
                <a-input
                  v-if="item.userTrainingRecord.tagInputVisible"
                  v-model:value="item.userTrainingRecord.tagInputValue"
                  :ref="`tagInput_${idx}`"
                  type="text"
                  size="small"
                  suffix="公斤"
                  :style="{ width: '78px' }"
                  @blur="handleTagInputConfirm(item)"
                  @keyup.enter="handleTagInputConfirm(item)"
                />
                <a-tag v-else style="background: #fff; border-style: dashed;" @click="showTagInput(idx)">
                  <plus-outlined /> 重量
                </a-tag>
              </template>
            </a-col>
          </a-row>
        </div>
        <!-- Training Records -->
        

        <!-- <a-row :gutter="24">
          <a-col :span="12" align="center">
            <div class="main-time-number">65</div>
            <div class="main-label">分鐘運動時長</div>
          </a-col>
          <a-col :span="12" align="center">
            <img src="@/assets/images/demo.jpeg" alt="" style="width: 80%;">
          </a-col>
        </a-row> -->
      </div>

      <div class="body-images" v-if="slicedImages.length">
        <a-carousel>
          <template #customPaging="props">
            <a class="px-1">
              <img :src="images[props.i].uri" style="width:65px; height:40px;" />
            </a>
          </template>
          <div class="pb-6" v-for="image in slicedImages">
            <img :src="image.uri" style="width:100%; height:220px; object-fit:cover;" />
          </div>
        </a-carousel>

        <div class="mt-12" align="center" v-if="images.length > 4">
          <a-button type="text">
            <span class="mr-2" @click="toImagesTab">點我查看更多上課照片</span>
            <span class="mdi mdi-arrow-right" />
          </a-button>
        </div>
      </div>

      <!-- Empty -->
      <div v-if="!trainingRecordsData.length && !images.length">
        <a-empty
          :image="simpleImage"
          description="還沒有成果紀錄"
          :style="{ paddingBottom: '20px' }"
        />
      </div>
      <!-- Empty -->
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import api from '@/apis'
import TrainingIcon from '/src/components/ui/TrainingIcon'
import { prop } from 'vue-class-component'

export default defineComponent({
  props: ['userId', 'images'],
  components: {
    PlusOutlined,
    TrainingIcon
  },
  setup(props) {
    const route = useRoute();

    /* Data */
    const trainingRecordsData = ref([]);
    const slicedImages = computed(() => {
      if (props.images.length > 4) return props.images.slice(0, 4);
      return props.images
    });

    /* Api */
    const getTrainingRecords = (async () => {
      const response = await api.v1.lession.findTrainingRecords(
        route.params.course_id,
        route.params.lession_id
      );

      // set user's training record & tag input
      response.data.result.forEach(item => {
        item.userTrainingRecord = {
          id: null, // user training record id
          tagInputRef: ref(),
          tagInputVisible: false,
          tagInputValue: ''
        }
      });

      trainingRecordsData.value = response.data.result;
    });

    const getUserTrainingRecords = (async () => {
      const params = {
        course_id: route.params.course_id,
        lession_id: route.params.lession_id
      }

      try {
        const response = await api.v1.user.findTrainingRecord(props.userId, params);
        console.log(response.data.result);

        // map the user's training record into training_record_id:weight
        const userTrainingRecordMap = new Map();
        response.data.result.forEach(item => {
          userTrainingRecordMap.set(item.training_record_id, { id: item.id, weight: item.weight });
        });
        
        // set into the trainingRecordsData
        trainingRecordsData.value.forEach(item => {
          if (userTrainingRecordMap.has(item.id)) {
            item.userTrainingRecord.id = userTrainingRecordMap.get(item.id).id;
            item.userTrainingRecord.tagInputValue = userTrainingRecordMap.get(item.id).weight;
          }
        });

        console.log(trainingRecordsData.value);
      } catch (error) {
        console.log(error);
      }
    });

    /* Mounted */
    onMounted(async () => {
      await getTrainingRecords();
      await getUserTrainingRecords();
    });

    return {
      /* Data */
      trainingRecordsData,
      slicedImages,

      /* Api */
      api,
      getUserTrainingRecords,

      /* Antd */
      message,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  methods: {
    /* Tab */
    toImagesTab() {
      this.$emit('changeTab', '4');
    },

    /* Tag Input */
    showTagInput(idx) {
      this.trainingRecordsData[idx].userTrainingRecord.tagInputVisible = true;

      const _this = this;
      this.$nextTick(() => {
        _this.$refs[`tagInput_${idx}`][0].focus();
      });
    },
    async handleTagInputConfirm(record) {
      if (!record.userTrainingRecord.tagInputValue) {
        record.userTrainingRecord.tagInputVisible = false;
        return
      }

      const data = {
        training_record_id: record.id,
        course_id: this.$route.params.course_id,
        lession_id: this.$route.params.lession_id,
        weight: record.userTrainingRecord.tagInputValue
      }

      let recordId = null;
      try {
        const response = await this.api.v1.user.insertOneTrainingRecord(this.userId, data);
        recordId = response.data.result.id;
      } catch (error) { 
        console.log(error);
        this.message.error('新增重量發生錯誤，請稍後再試');
        return
      }

      record.userTrainingRecord.tagInputVisible = false;
      record.userTrainingRecord.id = recordId;
    },
    async handleUserTrainingRecordDelete(record) {
      try {
        await this.api.v1.user.deleteOneTrainingRecord(this.userId, record.userTrainingRecord.id);
      } catch (error) {
        console.log(error);
        this.message.error('刪除重量紀錄發生錯誤，請稍後再試');
        return
      }

      record.userTrainingRecord.tagInputVisible = false;
      record.userTrainingRecord.id = null;
      record.userTrainingRecord.tagInputValue = '';
    }
  }
})
</script>

<style lang="scss">
.liff-lession-page-achievements {
  .achievement-card {
    .card__header {
      border-bottom: 1px solid #E0E0E0;

      .header-title {
        color: #FF8A00;
        font-size: 1.35rem;
        font-weight: 500;
        text-align: center;
        padding: 12px 24px;
      }
    }

    .card__body {
      .training-record-list {
        .record-item {
          height: 67px;
          margin-bottom: 24px;

          .item-image {
            height: 100%;
            display: flex;
            align-items: center;
            align-self: center;
          }

          .item-title {
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 8px;
          }

          .item-tag {
            font-size: 1rem;
          }
        }
      }
      
      .body-main {
        padding: 24px 28px;

        .main-time-number {
          font-size: 3rem;
          font-weight: bold;
        }

        .main-label {
          color: #616161;
          font-size: 1.2rem;
        }
      }

      .body-images {
        padding-bottom: 40px;
      }
    }
  }
}
</style>