<template>
<div class="liff-lession-page-homeworks p-5">
  <div
    class="homework-card letwecare-liff-lession-card"
    v-for="(item, idx) in data"
    v-if="data.length > 0"
  >
    <div class="card__body">
      <div class="body-title">作業{{ idx+1 }}. {{ item.name }}</div>
      <div class="body-description">{{ item.description }}</div>
    </div>
    
    <div class="card__actions">
      <template v-if="!item.completed">
        <a-button
          class="action-btn"
          type="primary"
          :style="{ background: '#FF8A00', borderColor: '#FF8A00' }"
          @click="completeHomework(item.id, item.name)"
        >完成作業</a-button>
      </template>
      <template v-else>
        <a-button
          class="action-btn"
          type="primary"
          :disabled="true"
          :style="{ }"
        >已完成</a-button>
      </template>
    </div>
  </div>

  <div v-if="data.length == 0">
    <a-empty class="mt-10" :image="simpleImage" description="沒有回家作業" />
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import api from '@/apis'

export default defineComponent({
  props: ['userId', 'data'],
  components: {},
  setup(props) {
    const route = useRoute();

    /* Data */
    const userHomeworksData = ref([]);
    
    /* Api */
    const getUserHomeworks = (async () => {
      const response = await api.v1.user.findUserHomeworks(props.userId, {
        course_id: route.params.course_id,
        lession_id: route.params.lession_id
      });
      userHomeworksData.value = response.data.result;
    });

    /* Mounted */
    onMounted(async () => {
      await getUserHomeworks();

      // 標註作業是否完成
      const completedIdList = userHomeworksData.value.map(item => item.homework_id);
      console.log(completedIdList)

      if (props.data.length) {
        props.data.forEach(item => {
          item.completed = false;

          if (completedIdList.includes(item.id)) {
            item.completed = true;
          }
        });
      }
    });

    return {
      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  methods: {
    async completeHomework(id, name) {
      try {
        await api.v1.user.insertOneUserHomework(this.userId, {
          course_id: this.$route.params.course_id,
          lession_id: this.$route.params.lession_id,
          homework_id: id
        });
        message.success(`完成 ${name}`);
      } catch (error) {
        console.log(error);
        message.error('記錄完成作業發生錯誤，請稍後再試');
      }
    }
  }
})
</script>

<style lang="scss">
.liff-lession-page-homeworks {
  .homework-card {
    padding: 24px;
    margin-bottom: 16px;

    .card__body {
      margin-bottom: 20px;

      .body-title {
        font-size: 1.4rem;
        font-weight: 450;
        margin-bottom: 12px;
      }

      .body-description {
        color: #424242;
        font-size: 1.2rem;
      }
    }

    .card__actions {
      .action-btn {
        width: 100%;
        font-size: 1.2rem;
        height: 44px;
      }
    }
  }
}
</style>